import format from "date-fns/format"


import formatDuration from "date-fns/formatDuration"
import intervalToDuration from "date-fns/intervalToDuration"
import parseISO from "date-fns/parseISO"

const ls = require("local-storage")

import api from 'helpers/apicalls.js'


class Student {

  constructor() {

  }


  getPhoto() {
    return this.photo || "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjYGBg+A8AAQQBAHAgZQsAAAAASUVORK5CYII="
  }

  gender() {
    return this.basic?.Gender
  }

  dob(formatted = false) {
    if (!this.basic) {
      return null
    }
    if (formatted) {
      return format(parseISO(this.basic.DOB), "do LLL yyyy")
    }
    return this.basic.DOB
  }


  age() {
    if (!this.basic) {
      return null
    }
    let age = intervalToDuration({ start: new Date(this.basic.DOB), end: new Date() })
    return formatDuration(age, { format: ["years", "months"], delimiter: "," })




  }

  getRTT(date = null, parsed = false) {

    let returnRTT = this._getRTT(date)

    console.log(returnRTT)

    if (!parsed) {
      return returnRTT
    }

    let timeTable = returnRTT.timetable.reduce((p, c) => {

      console.log({ p: JSON.parse(JSON.stringify(p)), c: JSON.parse(JSON.stringify(c)) })
      if (p.length == 0) {
        return [c];
      }

      let previous = p.at(-1)

      console.log({ len: p.length, prev: JSON.parse(JSON.stringify(previous)), c: JSON.parse(JSON.stringify(c)) })



      console.log({ msg: "Comparing", c: c.periodIdent, prev: previous.periodIdent })



      if (c.periodIdent != previous.periodIdent) {
        p.push(c);

        return p;
      }

      console.log("Matching ID")

      if (previous.isCover) {

        console.log("previous period is cover.......", c.isCover, previous.isCover)
        return p
      }


      console.log("Is the new one cover?", c.isCover)


      if (c.isCover) {


        c.staffName = c.notes
        c.notes = "for " + previous.staffName

        p[p.length - 1] = c


        return p
      }

      let d = p.pop()

      if (!d.others) {
        d.others = [c];
      } else if (
        d.others.length &&
        d.others.at(-1).groupIdent != c.groupIdent
      ) {
        d.others.push(c);
      }

      p.push(d);

      return p;
    }, [])

    console.log({ msg: "returning", timeTable })

    returnRTT.timetable = timeTable
    return returnRTT

  }

  _getRTT(date = null) {

    if (!this.rtt) {
      return undefined
    }

    if (date) {
      if (this.rtt[date]) {
        return { date: date, timetable: this.rtt[date] }
      }
      return undefined
    }

    let days = Object.keys(this.rtt)

    return { date: days[0], timetable: this.rtt[days[0]] }



  }

  tutor() {
    return this.basic?.ClassName ?? ""
  }


  house() {
    return this.basic?.House
  }

  teachers() {
    return this.teachers
  }

  candidateNumber() {
    let x = this.SEND ?? this.allData?.SEND
    return x?.["Candidate number"] ?? "TBC"
  }

  name(firstNameFirst = true) {

    console.log("I have called name()")

    if (!this.basic) {
      return ""
    }

    console.log("I have called name() and tehre is basic")



    let fn = (this.basic.FirstName ?? "?")
    let sn = (this.basic.LastName ?? "?")
    if (firstNameFirst)
      return fn + " " + sn
    return sn + ", " + fn
  }

  firstName() {
    return this.FirstName
  }

  parentPhoneNumbers() {

    return this.parents?.phoneNumbers ?? this.allData?.parents?.phoneNumbers ?? [];
  }

  parentSalutation() {
    return this.parents?.Salutation ?? this.allData?.parents?.Salutation ?? [];
  }

  accessArrangements() {
    return this.SEND?.["Access Arrangements"] ?? this.allData.SEND["Access Arrangements"]
  }

  getAttributes() {
    return Object.keys(this)
  }

  getMEGS() {
    return this.MEGS
  }

  getAlerts() {
    return this.ALERT ?? this.alerts
  }


  getID() {
    return this.PupilID
  }
}

async function getStudent(studentID) {

  console.log("Getting student: ", studentID)

  let student = ls("student-" + studentID)



  if (!student || student._cacheExpiry < Date.now()) {


    student = await api.get("v2/staff/student/" + studentID, true, true)

    console.log("api", student)

    student.PupilID = studentID

    student._cacheExpiry = Date.now() + 300000

    ls("student-" + studentID, student)


  }

  console.log("after:", student)

  let studentObj = Object.assign(new Student(), student)

  console.log({ id: studentID, studentObj })

  return studentObj


}

export { Student, getStudent }
