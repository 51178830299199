<template>
  <div>

    <form class="form">
      <div class="field">
        <label class="label">Why are you having the meeting?</label>
        <div class="control">
          <input class="input" type="text" placeholder="Reason for meeting">
        </div>
      </div>

      <div class="field">
        <label class="label">Who is attending?</label>
        <label v-if="student.parents.Adult1FirstName" class="checkbox">
          <input type="checkbox">
          {{ student.parents.Adult1Title }} {{ student.parents.Adult1FirstName }} {{ student.parents.Adult1LastName }} ({{
              student.parents.Adult1Relationship
          }})
        </label><br />
        <label v-if="student.parents.Adult2FirstName" class="checkbox">
          <input type="checkbox">
          {{ student.parents.Adult2Title }} {{ student.parents.Adult2FirstName }} {{ student.parents.Adult2LastName }} ({{
    student.parents.Adult2Relationship
          }})
        </label>
      </div>



    </form>
  </div>
</template>

<script>




export default {

  name: "parentMeeting",

  props: ["student", "studentIndex", "regDetails", "regMarks", "wibble"],

  //components: { Icon, },




  methods: {



  }

}

</script>