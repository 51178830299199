<template>




  <section class="section" style="padding-top:1em">
    <div v-if="loaded" class="container has-background-white-ter">

      <div class="boxTitle">
        <h4>{{ student.FirstName }} {{ student.LastName }}</h4>
      </div>

      <div class="box">

        <div class="tabs is-boxed is-toggle is-centered {padding-bottom:1em}">
          <ul>
            <li :class="mode == 'overview' ? 'is-active' : ''"                                  ><a @click="mode='overview'"      >Overview</a></li>
            <li :class="mode == 'reportCard' ? 'is-active' : ''"                                ><a @click="mode='reportCard'"    >Report Card</a></li>
            <li :class="mode == 'arrangeMeeting' ? 'is-active' : ''" v-if="runMode == 'testing'"><a @click="mode='arrangeMeeting'">Arrange a meeting</a></li>
          </ul>
        </div>




        <ReportCard    :locked="false"     v-if="mode == 'reportCard'"     :student="student" />

        <ParentMeeting v-else-if="mode == 'arrangeMeeting'" :student="student" />

        <div v-else>
          <div class="box">
            <p>More features coming soon</p>
          </div>
        </div>



      </div>
    </div>
    <ModalProgress v-else message="Retrieving student information" title="Loading...." />
  </section>

</template>

<script>


import ModalProgress from "Components/ModalProgress.vue";
import ReportCard from "Components/school/student/reportcard.vue"
import ParentMeeting from "Components/school/student/parentmeeting.vue"

import { getStudent } from '../../classes/student';


export default {
  name: "home",

  components: {
    ModalProgress,
    ReportCard,
    ParentMeeting
  },


  data: function () {
    return {
      loaded: false,
      student: null,
      mode: 'overview'
    }
  },







  computed: {

    runMode: function () { return this.$getConst('runMode') },



  },

  methods: {


















  },

  async mounted() {
    this.loaded = false

    console.log(this.$route.params.sid)


    getStudent(this.$route.params.sid)
      .then((response) => {
        console.log(response)
        this.student = response
      })
      .finally(() => {
        console.log("Wivvle")
        this.loaded = true;
      });

  },
};
</script>

<style>
i.pink {
  color: pink;
}

i.blue {
  color: blue;
}

button.ui.button {
  padding: 8px 3px 8px 10px;
  margin-top: 1px;
  margin-bottom: 1px;


}

a.btn-nav.page-link.disabled {
  color: lightgray
}
</style>